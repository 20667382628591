<template>
  <div ref = "modalContainer" class="botter-wdiget-body">
    <div :class="['botter-modal-chat', { 'botter-chat-body-shrinked': inputPulledUp }]">
      <div class="botter-modal-chat-container">
        <transition-group name="slide">
          <div 
            v-for="(con,index) in conversation" 
            :key="`${con.slug}${index}`">
            <component
              v-if = "inputPulledUp || !isInputPullingSlug(con.slug)"
              :is="getFileName(con.slug)"
              :con="con"
              :index="index"
              @postBack="postBack"
              @postText="postText"
              @postFile="postFile"
              @postEvent="postEvent"
              @postCustom="postCustom"
              @update-input="handleUpdateInput" />
          </div>
        </transition-group>
      </div>
    </div>
    <div class="botter-chat-infos">
      <a
        dir="ltr"
        href="https://botter.live/" 
        target="_blank">
        <botter-logo 
          :width="15" 
          :height="15"/>
        <span class="ml-2">We run on BOTTER.ai</span>
      </a>
    </div>
    <div :class="['botter-chat-action', { 'botter-chat-action-pulled-up': inputPulledUp }]" v-show="!inputDisabled">
      <snackbar v-if="!connected">
        {{ $t('You are disconnected we are trying to reconnect again') }}
      </snackbar>
      <form 
        class="botter-user-input d-flex align-center"
        @submit.prevent="sendMessage">
        <presist-menu style="height: 28px" @postBack="postBack"></presist-menu>

        <input
          v-if = "!inputPulledUp"
          v-model="userInput"
          :disabled="!connected || inputDisabled"
          type="text"
          class="botter-user-input-field"
          id="botter-user-input-field"
          spellcheck="true"
          :placeholder="$store.state.core.widgetParameters.inputPlaceHolder || 'write a replay'"
        >
        <div
          v-else
          class="botter-user-input-label">
          <p>{{ inputLabel }}</p>
        </div>
      </form>
      <div class="d-flex align-center">
        <input v-if="!$store.state.core.widgetParameters.showLocationLocator" type="file" class="botter-file-uploader" @change="uploadFile" ref="file" >
        <span v-if="!$store.state.core.widgetParameters.showLocationLocator" @click="$refs.file.click()" class="botter-svg d-flex align-center">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="19.798" 
            height="19.798" 
            viewBox="0 0 19.798 19.798">
            <g 
              id="prefix__noun_Paperclip_2766516" 
              transform="translate(-.086 -.086)" 
              style="opacity:.7">
              <g 
                id="prefix__Group_21" 
                data-name="Group 21" 
                transform="translate(.086 .086)">
                <path 
                  id="prefix__Path_6" 
                  d="M7.636 4.242V14A2.969 2.969 0 0 1 1.7 14V3.394a1.7 1.7 0 1 1 3.394 0v9.757a.424.424 0 1 1-.848 0V4.242h-1.7v8.908a2.121 2.121 0 0 0 4.242 0V3.394a3.394 3.394 0 1 0-6.788 0V14a4.666 4.666 0 0 0 9.333 0V4.242z" 
                  data-name="Path 6" 
                  transform="rotate(-135 7.4 8.532)"/>
              </g>
            </g>
          </svg>
        </span>
        <div class="widget-action-trigger" :class="{'activeActions' :activeActions}">
          <v-icon class="plug-trigger" v-if="$store.state.core.widgetParameters.showLocationLocator"  @click="activeActions = !activeActions">mdi-plus</v-icon>
          <widget-actions v-if="$store.state.core.widgetParameters.showLocationLocator">


            <location-locator @getLocation="postLocation" class="nds nd1"/>
            <input type="file" class="botter-file-uploader " @change="uploadFile" ref="file" >
            <div  @click="$refs.file.click()" class="botter-svg nds nd2">
              <div class="tooltip">   <v-icon>mdi-attachment</v-icon>
                <span class="tooltiptext">{{$t('send attachment')}}</span>
              </div>
            </div>
          </widget-actions>
        </div>

        <send-button @click = "handleSendAction" />
        <div id="sound"></div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../utils/common'
import BotSocket from '../modules/botSocket'
import textData from './messagesType/textData'
import imageData from './messagesType/imageData'
import heroData from './messagesType/heroData'
import triviaQuestionData from './messagesType/triviaQuestionData'
import galleryData from './messagesType/galleryData'
import typingData from './messagesType/typingData'
import videoData from './messagesType/videoData'
import audioData from './messagesType/audioData'
import flightStatusData from './messagesType/flightStautsData'
import flightPassngersData from './messagesType/travelData'
import adaptiveCard from './messagesType/adaptiveCard'
import snackbar from './UIElements/snackbar'
import sendButton from './UIElements/sendButton'
import BotterLogo from './UIElements/botterLogo'
import gifData from './messagesType/gifData'
import cardDateTimeInputData from './messagesType/cardDateTimeInputData'
import weatherData from './messagesType/weatherData'
import receiptData from './messagesType/receiptData'
import mapData from "./messagesType/mapData";
import linkedImageData from "./messagesType/linkedImageData";
import attachmentData from "./messagesType/attachment";
import userInputData from "./messagesType/userInputData";
import multiInputData from "./messagesType/multiInputData";
import escalateData from "./messagesType/escalateData";
import presistMenu from "./menu/presistMenu";
import locationLocator from "./locationLocator";
import widgetActions from "./common/widgetActions";
import calenderBookingData from "./messagesType/calenderBooking";
import orderData from './messagesType/orderCard'
import bookAppointmentData from "./messagesType/bookAppointmentData";
export default {
  name: 'WidgetBody',
  components: {
    BotterLogo,
    textData,
    imageData,
    heroData,
    triviaQuestionData,
    galleryData,
    typingData,
    videoData,
    audioData,
    adaptiveCard,
    snackbar,
    sendButton,
    gifData,
    flightStatusData,
    flightPassngersData,
    cardDateTimeInputData,
    weatherData,
    receiptData,
    mapData,
    linkedImageData,
    attachmentData,
    userInputData,
    multiInputData,
    presistMenu,
    escalateData,
    calenderBookingData,
    locationLocator,
    widgetActions,
    orderData,
    bookAppointmentData
  },
  props: {
    sessionActive: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    modalActive: {
      type: Boolean,
      default: false
    },
    onFocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userInput: '',
      inputLabel: '',
      socket: {},
      slugs: [
        'text',
        'image',
        'attachment',
        'hero',
        'gallery',
        'typing',
        'video',
        'audio',
        'gif',
        'trivia-question',
        'flight-status',
        'flight-passngers',
        'card-date-time-input',
        'weather',
        'receipt',
        'map',
        'linked-image',
        'user-input',
        'multi-input',
        'escalate',
        'calender-booking',
        'order',
        'book-appointment'
      ],
      textSlugs: ['prompt-input','prompt-date','prompt-multi-input'],
      adaptiveCards: ['receipt', 'weather'],
      bottomInputSlugs: [''],
      activeActions: false
    }
  },

  computed: {
    conversation() {
      return this.$store.state.messages.conversationList
    },
    connected() {
      return this.$store.state.messages.connected
    },
    inputDisabled() {
      return this.$store.state.messages.inputDisabled
    },
    inputPulledUp() {
      if (this.conversation && this.conversation.length)
        return this.isInputPullingSlug(
          this.conversation[this.conversation.length - 1].slug
        )
      return false
    }
  },
  watch: {
    'conversation.length': function() {
      if (common) common.scrollHeight()
    },
    sessionActive() {
      if (this.sessionActive) {
        this.initSession()
      }
    },
    userInput() {
      let arabic = /[\u0600-\u06FF]/
      let input = document.getElementById('botter-user-input-field')
      if (arabic.test(this.userInput)) {
        input.style.direction = 'rtl'
      } else  {
        input.style.direction = 'ltr'
      }
    },
    conversation() {}
  },
  mounted() {
    // listen to enter press
    document.addEventListener('keydown', e => {
      if (e.key === 'Enter')
        this.submitFakeInput()
    })
    this.initSession()


  },
  methods: {
    initSession() {
      this.socket = new BotSocket()
    },
    async sendMessage() {

      if (this.userInput && (this.userInput.replace(/\s/g, '').length) ) {
        try {
          this.userInput = common.sanitizeString(this.userInput)
          await this.socket.sendMessage({
            text: this.userInput,
            type: 'message',
            slug: 'text'
          })
          await this.$store.dispatch('pushMessage', {
            slug: 'text',
            payload: this.userInput,
            type: 'user',
            lastMessageGroup: true
          })
          this.userInput = ''
        } catch (err) {
          // error to be handled
        }
      }
    },
    async uploadFile(event) {
      let formObject = new FormData()
      this.activeActions = false
      formObject.append("file", event.target.files[0])
      this.$http_upload.post('/file/upload',formObject)
        .then(response=>{
          let image = response.data.url
           this.$store.dispatch('pushMessage', {
            slug: response.data.type,
            mediaUrl: image,
            type: 'user',
             fileName: response.data.name,
            lastMessageGroup: true
          })

          let attachmentObject =  {
            type: 'attachment',
            url: image,
            attachment_type: response.data.type
          }

          this.socket.sendMessage({
            slug: response.data.type,
            type: 'message',
            file_name: response.data.name,
            text: JSON.stringify(attachmentObject)
          })
        })
        .catch(error=>{

        })
    },
    postFile(file) {
      let attachmentObject =  {
        type: 'attachment',
        url: file.url,
        attachment_type: file.type
      }

      this.socket.sendMessage({
        slug: file.type,
        type: 'message',
        file_name: file.name,
        text: JSON.stringify(attachmentObject)
      })
    },
    postEvent(eventName) {
      this.socket.sendMessage({
        event: eventName,
        type: 'report_event',
      })
    },
    async postBack(postData) {
      await this.socket.sendMessage({
        text: postData.value || postData.payload,
        type: 'message',
        postback: postData
      })
    },
    postText (postData) {
      this.socket.sendMessage({
        text: postData,
        type: 'message',
        slug: 'text'
      })
    },
    postCustom(postData) {
      this.socket.sendMessage(postData)
    },
    postLocation (cord) {
      this.activeActions = false
      let text = JSON.stringify(cord)
      // this.socket.sendMessage({
      //   type: 'location',
      //   text,
      //   coordinates: { lat: cord.latitude, long: cord.longitude },
      // })
      this.$store.dispatch('pushMessage', {
        slug: 'linked-image',
        mediaUrl: cord.image,
        type: 'user',
        fileName: 'location',
        lastMessageGroup: true
      })

      let attachmentObject =  {
        type: 'attachment',
        url: cord.image,
        latitude: cord.latitude,
        longitude: cord.longitude,
        attachment_type: 'location'
      }

      this.socket.sendMessage({
        slug: 'image',
        type: 'message',
        file_name: 'location',
        text: JSON.stringify(attachmentObject)
      })

    },
    getFileName(slug) {
      if (this.slugs.includes(slug)) {
        return slug + '-data'
      } else if (this.textSlugs.includes(slug)) {
        return 'text-data'
      }
    },
    // decides whether the given slug will pull the actionInput up or not
    isInputPullingSlug(slug) {
      return this.bottomInputSlugs.includes(slug)
    },
    handleUpdateInput(e) {
      this.userInput = e
      if (e.label) this.inputLabel = e.label
    },
    handleSendAction() {
      this.sendMessage()
    },
    submitFakeInput() {
      if (this.onFocus && this.inputPulledUp)
        this.sendMessage()
    }
  }
}
</script>

<style  lang="scss" src="../style/components/widgetBody.scss">
</style>
